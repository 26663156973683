<template>
  <div>
    <div class="customerForm inner">
      <div class="mt-5">
        <div class="form-header-wrap">
          <h2 class="doFormTitle">Delivery Order Form</h2>
          <div class="flex flex-column align-start">
            <div>
              <span class="doFormSubTitle"
                >Delivery Order for Import FCL only (Export & LCL options are
                coming soon)</span
              >
            </div>
            <div>
              <span class="doFormSubTitle">
                Fields with marked <span class="text-red">*</span> are required
              </span>
            </div>
          </div>
        </div>

        <v-form ref="form" v-model="valid" width="960">
          <div class="px-6">
            <div class="mt-5 mb-5">
              <label class="text-item-label"
                >Delivery Order Document <span class="text-red">*</span></label
              >
              <div
                :class="this.fields.documents.length > 0 ? 'd-none' : ''"
                class="flex flex-row flex-wrap"
              >
                <div
                  style="display: block; width: 100%"
                  class="h-screen items-left justify-left text-left"
                  id="app"
                >
                  <div
                    style="width: 100%"
                    @dragover="dragover"
                    @dragleave="dragleave"
                    @drop="dropFile"
                  >
                    <input
                      style="display: none"
                      type="file"
                      name="fields[]"
                      id="screenshots"
                      class="w-px h-px opacity-0 overflow-hidden absolute"
                      ref="file"
                      accept="application/pdf"
                      @change="onChange()"
                    />
                    <label
                      for="screenshots"
                      class="block cursor-pointer triggerelement"
                    >
                      <div class="flex">
                        <div class="drop-field">
                          <div class="browser-text text-center">
                            <span class="">Browse or Drop File here</span>
                          </div>
                          <div class="btn-white2 browser-btn">
                            <div class="upload-btn mt-2">
                              <span class="iconUploadfile"
                                ><i class="ic-upload"></i
                              ></span>
                              <span class="classUpload">Upload</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <span
                style="color: red; font-size: 12px"
                v-if="documentErrorMsg !== '' && fields.documents.length == 0"
                >{{ documentErrorMsg }}</span
              >
              <div
                class="flex w-full h-screen items-left justify-left text-left"
                style="width: 100%"
              >
                <ul
                  class="displayItem flex flex-column mt-4"
                  v-if="this.fields.documents.length"
                  v-cloak
                >
                  <li
                    class="text-sm p-1 mb-3"
                    style="width: 100%"
                    v-for="(file, index) in this.fields.documents"
                    :key="index"
                  >
                    <div
                      style="display: flex; align-items: center"
                      class="rowItemUploaded flex justify-lg-space-between"
                    >
                      <div class="fileNameList" style="top: 0px !important">
                        <span
                          class="IconUpload"
                          style="background: #fff; border-radius: 30px"
                        >
                          <img
                            class="imgIcon"
                            src="../../assets/icons/documentFile-white.svg"
                          />
                        </span>
                        {{ file.name }}
                      </div>
                      <div
                        style="display: flex; align-items: center"
                        class="mainwrapitem"
                      >
                        <div
                          class="flex justify-end fileSize"
                          v-if="uploadCompleted"
                        >
                          <button
                            type="button"
                            class="replace-btn mx-3"
                            style="position: unset !important"
                            @click="handleReplace"
                          >
                            Replace
                          </button>
                          <a
                            class="ml-2 document-btn d-flex justify-center align-center"
                            target="_blank"
                            :href="getFileURL(file)"
                          >
                            <img
                              class="d-flex justify-center align-center"
                              style="cursor: pointer"
                              src="../../assets/icons/view-blue.svg"
                            />
                          </a>
                          <button
                            class="ml-2 document-btn"
                            type="button"
                            @click="removeFile(fields.documents.indexOf(file))"
                            title="Remove file"
                          >
                            <i style="color: red" class="ic-delete"> </i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                  <small style="color: #f93131 !important" v-if="moreFilesError"
                    >Only 1 file can be uploaded</small
                  >
                </ul>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-6 pa-0">
                <div class="flex flex-column">
                  <div class="col-md-12">
                    <label class="text-item-label"
                      >Company Name <span class="text-red">*</span></label
                    >
                    <div v-if="isCustomer">
                      <!-- 
                                                don't remove, let's wait for the confirmation if should use dropdown
                                                <v-autocomplete
                                                v-if="isReload"
                                                v-model="customerData"
                                                :items="customerdropdown"
                                                :rules="customerRules"
                                                item-text="company_name"
                                                item-value="id"
                                                placeholder="Select Customer"
                                                solo                                            
                                                append-icon="ic-chevron-down"
                                                >
                                            </v-autocomplete> -->
                      <v-text-field
                        type="text"
                        placeholder="Enter Company Name"
                        outlined
                        :disabled="disabled"
                        class="text-fields"
                        v-model="fields.company_name"
                        :rules="fieldrequired"
                      />
                    </div>
                    <div v-else>
                      <v-text-field
                        type="text"
                        placeholder="Enter Company Name"
                        outlined
                        class="text-fields"
                        v-model="fields.company_name"
                        :rules="fieldrequired"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <label class="text-item-label"
                  >Delivery Contacts <span class="text-red">*</span></label
                >
                <div class="tags-email-wrapper mb-1">
                  <vue-tags-input
                    hide-details="auto"
                    v-model="dc"
                    :tags="delivery_contacts"
                    :add-on-blur="true"
                    class="text-fields"
                    :add-on-key="[13, ',']"
                    placeholder="e.g example@domain.com"
                    :validation="
                      delivery_contacts.length > 0
                        ? tagsValidation
                        : tagsValidation
                    "
                    @tags-changed="(newTags) => (delivery_contacts = newTags)"
                  />
                </div>
                <small
                  class="text-red"
                  v-if="errorMsg !== '' && !fields.delivery_contacts.length > 0"
                  >{{ errorMsg }}</small
                >
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 pt-0">
                <label class="text-item-label">CONSIGNEE</label>
                <div class="tags-email-wrapper mb-1">
                  <v-autocomplete
                    class="text-fields carrieerTextfield"
                    :items="consigneeList"
                    item-text="name"
                    item-value="id"
                    placeholder="Select or Add Consignee"
                    solo
                    append-icon="mdi-chevron-down"
                    v-model="fields.consignee"
                    :search-input.sync="getSearchedConsignee"
                  >
                  <div slot="no-data"></div>
                    <div
                      v-if="hasNewChassis"
                      class="v-list-item v-list-item__title add-option-btn py-2"
                      slot="append-item"
                      @click="handleNewConsignee"
                      style="font-size: 14px;"
                    >                  
                      <span class="pr-1" style="color: #0171A1;">+ Add New</span>&nbsp;<strong style="color: #4a4a4a;">
                        {{ newConsignee }}</strong>
                    </div>
                  </v-autocomplete>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 pt-0">
                <label class="text-item-label">My Reference Numbers</label>
                <div class="tags-email-wrapper mb-1">
                  <vue-tags-input
                    hide-details="auto"
                    v-model="crn"
                    :tags="crn_tags"
                    :add-on-blur="true"
                    class="text-fields"
                    :add-on-key="[13, ',']"
                    placeholder="Enter Reference Number"
                    @tags-changed="(newTags) => (crn_tags = newTags)"
                  />
                </div>
              </div>
              <div class="col-md-6 pt-0">
                <label class="text-item-label"
                  >Receiving Location Contact</label
                >
                <v-text-field
                  type="text"
                  placeholder="Enter Phone Number"
                  outlined
                  class="text-fields"
                  v-model="fields.receiving_location_contact"
                  :rules="phoneNumberValidation"
                />
              </div>
            </div>
         
            <div class="row mt-1">
              <div class="col-md-6 pb-0 selectBox">
                <label class="text-item-label">Carrier</label>
                <v-autocomplete
                  class="text-fields carrieerTextfield"
                  :items="carriers"
                  item-text="name"
                  item-value="id"
                  placeholder="Select Carrier"
                  solo
                  append-icon="mdi-chevron-down"
                  v-model="fields.carrier"
                >
                </v-autocomplete>
              </div>
              <div class="col-md-6 pb-0 selectBox">
                <label class="text-item-label">Vessel</label>
                <v-text-field
                  type="text"
                  placeholder="Vessel"
                  outlined
                  class="text-fields"
                  v-model="fields.vessel"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 pb-0">
                <label class="text-item-label"
                  >MBL/AWB Number <span class="text-red"></span
                ></label>
                <v-text-field
                  type="text"
                  placeholder="MBL/AWL Number"
                  outlined
                  class="text-fields"
                  v-model="fields.mbl_number"
                />
              </div>
              <div class="col-md-6 pb-0 vc-date-picker__wrapper">
                <label class="text-item-label"
                  >Expected Time of Arrival
                  <span class="text-red">*</span></label
                >
                <!-- <v-text-field
                                    type="text"
                                    placeholder="Select ETA Date"
                                    outlined
                                    class="text-fields expectedTimeArrival"
                                    append-icon="ic-calendar"
                                    v-model="fields.eta"
                                    :rules="dateValidation"
                                    @click="pickerLabel = 'Pick ETA Date'; isDatePickerOpen = true;"
                                /> -->
                <div
                  class="vc-datepicker-with-clear"
                  :class="fields.eta !== '' ? '' : 'close-button-check-lfd'"
                >
                  <!-- <vc-date-picker
                    title-position="left"
                    is-expanded
                    v-model="fields.eta"
                    mode="date"
                    :popover="{ visibility: 'focus' }"
                    :firstDayOfWeek="1"
                    :masks="masks"
                    :min-date="nowDate"
                    :max-date="getEndDate"
                    :select-attribute="attribute"
                    @change="changeDateFormat"
                    @input="(e) => checkErrorShowing(e, 'eta')"
                    ref="etaCf_ref_do"
                    :is-required="isDateRequired"
                  >
                    <template #default="{ inputValue, inputEvents }">
                      <input
                        :value="inputValue"
                        v-on="inputEvents"
                        class="create-do-eta-dxdate-vc"
                        placeholder="Select ETA"
                        @keyup="(e) => checkKeyUpVal(e)"
                      />
                    </template>
                  </vc-date-picker> -->
                
                  <vc-date-picker
                    title-position="left"
                    is-expanded
                    v-model="fields.eta"
                    mode="date"
                    :popover="{ visibility: 'focus' }"
                    :firstDayOfWeek="1"
                    :masks="masks"
                    :min-date="minDate"
                    :select-attribute="attribute"
                    @change="changeDateFormat"
                    @input="(e) => checkErrorShowing(e, 'eta')"
                    ref="etaCf_ref_do"
                    :is-required="isDateRequired"
                  >
                    <template #default="{ inputValue, inputEvents }">
                      <input
                        :value="inputValue"
                        v-on="inputEvents"
                        class="create-do-eta-dxdate-vc"
                        placeholder="Select ETA"
                        @keyup="(e) => checkKeyUpVal(e)"
                      />
                    </template>
                  </vc-date-picker>
                  <button
                    type="button"
                    class="vc-date-clear-button"
                    @click="clearDate"
                    v-if="fields.eta !== null && fields.eta !== ''"
                  >
                    <v-icon size="18px" color="#F93131">mdi-close</v-icon>
                  </button>
                  <span
                    v-if="customDateValidation(fields.eta) !== '' && dateReq"
                    style="color: red; font-size: 12px"
                    >{{ customDateValidation(fields.eta) }}
                  </span>
                </div>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-md-6 pb-0">
                <label class="text-item-label">Pickup Location</label>
                <v-autocomplete
                  class="text-fields"
                  v-model="terminal"
                  :items="theterminals"
                  placeholder="Terminal"
                  item-text="name"
                  item-value="id"
                  solo
                  append-icon="mdi-chevron-down"
                  name="custom_notes"
                ></v-autocomplete>
              </div>
              <div class="col-md-6 pb-0">
                <label class="text-item-label">Delivery Location</label>
                <!-- <textarea
                                    rows="5"
                                    class="textarea-field"
                                    placeholder="Delivery Location"
                                    v-model="fields.delivery_location"
                                >
                                </textarea> -->
                <v-autocomplete
                  class="text-fields"
                  :loading="loadingLocationPlaces"
                  v-model="fields.delivery_location"
                  :items="places"
                  :search-input.sync="searchPlace"
                  placeholder="Type Delivery Addresss"
                  item-text="place_name"
                  no-filter
                  clearable
                  hide-no-data
                  @click:clear="clearSearchPlaces"
                  @change="changeDeliveryLocationState"
                  solo
                  full-width
                  append-icon="mdi-chevron-down"
                  name="custom_notes"
                ></v-autocomplete>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-md-6 pb-0">
                <label class="text-item-label">Receiving Location Days</label>
                <v-select
                  ref="Vueselect"
                  :items="openDays"
                  class="select-activity text-fields shrink receivingdays text-fields"
                  item-text="activity"
                  item-value="activity"
                  outlined
                  placeholder="Customer Actvity"
                  chips
                  attach
                  append-icon="mdi-chevron-down"
                  :background-color="'white'"
                  hide-details="auto"
                  :menu-props="{
                    contentClass: '',
                    bottom: true,
                    offsetY: true,
                    maxHeight: 500,
                    maxWidth: 500,
                  }"
                  multiple
                  v-model="fields.receiving_location_days"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip class="v-chip--select">
                      {{ item.substring(0, 3) }},
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item
                      class="d-flex justify-start"
                      style="border: none; min-height: 45px; height: 45px"
                      v-on="on"
                      v-bind="attrs"
                      #default="{ active }"
                    >
                      <div><v-checkbox :input-value="active"></v-checkbox></div>
                      <div>
                        <p
                          class="name mt-9 font-regular"
                          style="color: #4a4a4a"
                        >
                          {{ item }}
                        </p>
                      </div>
                    </v-list-item>
                  </template>
                </v-select>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6 pb-0">
                    <label class="text-item-label">Receiving Opens</label>
                    <!-- <v-text-field
                                            class="text-fields receivingOpens"
                                            outlined
                                            type="text"
                                            hide-details="auto"
                                            v-model="receivingTimeFromText"
                                            placeholder="Set Time"
                                            append-icon="mdi-clock-time-four-outline"
                                            @change="handleTimeChange(receivingTimeFromText,'from')"
                                            dense
                                        ></v-text-field> -->

                    <div class="dxdatebox-time-clear">
                      <DxDateBox
                        v-model="receivingTimeFromText"
                        type="time"
                        class="date-field-dxtime"
                        @value-changed="
                          (e) => {
                            handleReceivingTimeChanged(e, 'from');
                          }
                        "
                        :use-mask-behavior="true"
                        display-format="hh:mm a"
                        placeholder="Set Time"
                        :openOnFieldClick="true"
                        :show-clear-button="true"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 pb-0">
                    <label class="text-item-label">Receiving Closes</label>
                    <div class="dxdatebox-time-clear">
                      <DxDateBox
                        v-model="receivingTimeToText"
                        type="time"
                        class="date-field-dxtime"
                        @value-changed="
                          (e) => {
                            handleReceivingTimeChanged(e, 'to');
                          }
                        "
                        :use-mask-behavior="true"
                        display-format="hh:mm a"
                        placeholder="Set Time"
                        :openOnFieldClick="true"
                        :show-clear-button="true"
                      />
                    </div>                  
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-12">
                <label class="text-item-label"
                  >Description of Articles, Special Marks & Exceptions</label
                >
                <textarea
                  rows="5"
                  style="resize: none"
                  class="textarea-field"
                  placeholder="Description of Articles, Special Marks & Exceptions"
                  v-model="fields.description"
                >
                </textarea>
              </div>
            </div>
          </div>
        </v-form>

        <v-form v-model="containerValid" lazy-validation ref="containerForm">
          <div class="row mt-5">
            <div class="col-md-12 px-9">
              <p style="color: red" v-if="errorlfd">
                LFD must be later than ETA.
              </p>
              <v-data-table
                class="add-containers-table customerform_addContainers"
                :headers="headers"
                :items="fields.containers"
                :items-per-page="5"
                hide-default-footer
                dense
              >
                <template v-slot:item="{ item, index }">
                  <!-- <tbody> -->
                  <!-- <tr v-for="(item, index) in items" :key="index">
                                            <td class="text-start">
                                                {{ item.container_number }}
                                            </td>
                                            <td class="text-end">
                                                {{ item.container_size }}
                                            </td>
                                            <td class="text-end">
                                                {{ item.container_weight }}
                                            </td>
                                            <td class="text-end">
                                                {{ item.container_volume }}
                                            </td>
                                            <td class="text-end">
                                                {{item.container_qty}} -->
                  <!-- <input type="text" v-model="item.container_qty" />   -->
                  <!-- </td>
                                            <td class="text-end">
                                                {{item.lfd}} -->
                  <!-- <input type="text" v-model="item.lfd"  @click=" {
                                                            pickerLabel = 'Pick LFD Date'
                                                            pickerEdit=1
                                                            isDatePickerOpen = true
                                                            currentindex = index
                                                        }" />                                                -->
                  <!-- </td> -->
                  <!-- <td class="text-center">
                                                <span @click="removeContainer(index)" style="color: red; cursor: pointer;">X</span>
                                            </td> -->
                  <!-- </tr> -->
                  <tr>
                    <td
                      class="text-start"
                      style="border: 1px solid #ebf2f5; width: 200px"
                    >
                      <v-text-field
                        type="text"
                        dense
                        hide-details="auto"
                        placeholder="Enter Container Number"
                        class="text-fields container-nbr-ph"
                        flat
                        solo
                        v-model="item.container_number"
                        validate-on-blur
                        @input="inputText"
                        :rules="[checkforErrors(item, index)]"
                      />

                      <!-- {{checkforErrors(item,index)}} -->
                    </td>
                    <td
                      class="text-end"
                      style="border: 1px solid #ebf2f5; border-left: none"
                    >
                      <v-autocomplete
                        v-model="item.container_size"
                        :items="sizes"
                        placeholder="Enter Size"
                        item-text="name"
                        item-value="id"
                        class="text-fields container-size"
                        append-icon="mdi-chevron-down"
                        no-filter
                        dense
                        hide-details="auto"
                        flat
                        clearable
                        hide-no-data
                        @click:clear="clearSearchPlaces"
                        full-width
                      ></v-autocomplete>
                    </td>
                    <td
                      class="text-end"
                      style="border: 1px solid #ebf2f5; border-left: none"
                    >
                      <v-text-field
                        flat
                        solo
                        type="number"
                        placeholder="0"
                        class="text-fields inlinefield text-end"
                        dense
                        hide-details="auto"
                        full-width
                        v-model="item.container_weight"
                      >
                        <template slot="append">
                          <p
                            style="font-size: 14px; color: #819fb2"
                            class="mt-1"
                          >
                            KG
                          </p>
                        </template>
                      </v-text-field>
                    </td>
                    <td
                      class="text-end"
                      style="border: 1px solid #ebf2f5; border-left: none"
                    >
                      <v-text-field
                        flat
                        solo
                        type="number"
                        placeholder="0"
                        class="text-fields inlinefield text-end"
                        full-width
                        dense
                        hide-details="auto"
                        v-model="item.container_volume"
                      >
                        <template slot="append">
                          <p
                            style="font-size: 14px; color: #819fb2"
                            class="mt-1"
                          >
                            CBM
                          </p>
                        </template>
                      </v-text-field>
                    </td>
                    <td
                      class="text-end"
                      style="border: 1px solid #ebf2f5; border-left: none"
                    >
                      <v-text-field
                        flat
                        solo
                        type="number"
                        placeholder="0"
                        class="text-fields text-end"
                        dense
                        hide-details="auto"
                        full-width
                        v-model="item.container_qty"
                      />
                    </td>
                    <td
                      class="text-end vc-date-picker__wrapper"
                      style="border: 1px solid #ebf2f5; border-left: none"
                    >
                      
                      <div
                        class="vc-datepicker-with-clear"
                        :class="item.lfd !== '' ? '' : 'close-button-check-lfd'"
                        style="position: absolute; margin-top: -23px"
                      >
                        <vc-date-picker
                          title-position="left"
                          is-expanded
                          v-model="item.lfd"
                          mode="date"
                          :popover="{ visibility: 'focus' }"
                          :firstDayOfWeek="1"
                          :masks="masks"
                          :min-date="getMinDate()"
                          :select-attribute="attribute"
                          @change="changeDateFormat"
                          @input="(e) => checkErrorShowingLFD(e, 'lfd', index)"
                          ref="lfd_ref_do"
                        >
                          <template #default="{ inputValue, inputEvents }">
                            <input
                              style="border: none; width: 150px"
                              :value="inputValue"
                              v-on="inputEvents"
                              class="create-do-eta-dxdate-vc"
                              placeholder="Select LFD"
                              @keyup="(e) => checkKeyUpVal(e)"
                            />
                          </template>
                        </vc-date-picker>

                        <button
                          type="button"
                          class="vc-date-clear-button"
                          @click="clearDateContainer(item.lfd, index)"
                          v-if="item.lfd !== null && item.lfd !== ''"
                          style="top: 9px"
                        >
                          <v-icon size="18px" color="#F93131">mdi-close</v-icon>
                        </button>
                      </div>
                    </td>
                    <td
                      class="text-center"
                      style="border: 1px solid #ebf2f5; border-left: none"
                    >
                      <v-icon
                        size="19"
                        :style="
                          checkforFirstRowDataFunction(item)
                            ? 'color:gray'
                            : 'color:red'
                        "
                        @click="removeContainer(item, index)"
                        style="cursor: pointer"
                        >mdi-close</v-icon
                      >
                      <!-- <span>X</span> -->
                    </td>
                    <!-- <td class="text-center">
                                                <span @click="handleAddContainer" class="add-btn">+</span>
                                            </td> -->
                  </tr>
                  <tr
                    v-if="
                      checkforErrors(item, index) == false &&
                      checkforErrorsMsg(item.container_number, index) !== ''
                    "
                  >
                    <td
                      v-if="checkforErrors(item, index) == false"
                      colspan="12"
                    >
                      <span
                        v-if="checkforErrors(item, index) == false"
                        style="color: red; font-size: 12px"
                        >{{
                          checkforErrorsMsg(item.container_number, index)
                        }}</span
                      >
                    </td>
                  </tr>
                  <tr v-else-if="!checked && checkingg(item, index) !== ''">
                    <td colspan="12">
                      <span style="color: red; font-size: 12px">{{
                        checkingg(item, index)
                      }}</span>
                    </td>
                  </tr>

                  <!-- </tbody> -->
                </template>
              </v-data-table>
              <div style="width: 200px; border: 1px solid #ebf2f5">
                <v-btn
                  text
                  class="btn-white"
                  block
                  style="border: none !important; justify-content: flex-start"
                  @click="handleAddContainer"
                >
                  <v-icon size="19">mdi-plus</v-icon> Add Container
                </v-btn>
              </div>
            </div>
          </div>
        </v-form>

        <div class="form-footer-wrap mt-6">
          <div class="flex flex-row">
            <button @click="handleSubmit" type="button" class="submit-btn">
              Submit
            </button>
            <button @click="closenow" type="button" class="cancel-btn ml-2">
              Close
            </button>
          </div>
        </div>
      </div>

      <DatePickerDialog
        :open="isDatePickerOpen"
        :multiple="false"
        :label="pickerLabel"
        @confirm="confirmDatePicker"
        @close="isDatePickerOpen = false"
      />
      <Containerrequired
        :dialogData.sync="dialogrequired"
        @close="
          () => {
            dialogrequired = false;
          }
        "
      />
    </div>
  </div>
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";
import jQuery from "jquery";
import DatePickerDialog from "../../components/Tables/Containers/Dialog/DatePickerDialog.vue";
import globalMethods from "../../utils/globalMethods";
import Containerrequired from "../Tables/DeliveryOrder/dialog/Onecontainerrequired.vue";
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import DxDateBox from "devextreme-vue/date-box";

export default {
  name: "CustomerDeliveryOrderForm",
  props: [
    "trucker",
    "carriers",
    "sizes",
    "consignee",
    "hasSubmitted",
    "customer",
    "selectedCustomer",
    "customerKey"
  ],
  components: {
    VueTagsInput,
    DatePickerDialog,
    Containerrequired,
    DxDateBox,
  },
  data(){
    return {
    consigneeListobject: [],  
    newConsignee: "",
    hasNewChassis: 0,
    disabled: true,
    isReload: 1,
    customerRules: [(v) => !!v || "Customer is required"],
    customerData: "",
    minDate: "",
    isDateRequired: false,
    dateReq: false,
    dateNow: new Date(),
    nowDate: new Date().toISOString().slice(0, 10),
    attribute: {
      highlight: {
        style: {
          backgroundColor: "#0171A1", // blue
          borderRadius: "4px",
        },
        contentStyle: {
          color: "#ffffff", // color of the text
          borderRadius: "4px",
        },
      },
    },
    errorlfd: 0,
    terminal: "",
    theterminalitems: [],
    valid: true,
    containerValid: true,
    isAdding: false,
    errorFile: false,
    allFieldsValid: false,
    moreFilesError: false,
    uploadCompleted: true,
    isDatePickerOpen: false,
    modal_ps_time: false,
    modal_closing_time: false,
    pickerDefaultdata: "",
    currentstatepicker: false,
    dialogDatepicker: false,
    loadingLocationPlaces: false,

    searchPlace: "",
    searchPlacePickup: "",
    crn: "",
    dc: "",
    pickerLabel: "Pick ETA Date",
    currentindex: 0,
    pickerEdit: 0,
    errorMsg: "",
    currentIndexOfLfd: 0,

    openDays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    headers: [
      {
        text: "Container #",
        align: "start",
        sortable: false,
        value: "container_number",
        fixed: true,
        width: "200px",
      },
      {
        text: "Size",
        align: "start",
        sortable: false,
        value: "size",
        fixed: true,
        width: "8%",
      },
      {
        text: "Weight",
        align: "end",
        sortable: false,
        value: "weight",
        fixed: true,
        width: "15%",
      },
      {
        text: "Volume",
        align: "end",
        sortable: false,
        value: "volume",
        fixed: true,
        width: "15%",
      },
      {
        text: "Quantity",
        align: "end",
        sortable: false,
        value: "quantity",
        fixed: true,
        width: "15%",
      },
      {
        text: "LFD",
        align: "center",
        sortable: false,
        value: "delivery_to",
        fixed: true,
        width: "20%",
      },
      // {
      //     text: 'Delivery Location',
      //     align: 'center',
      //     sortable: false,
      //     value: 'delivery_to'
      // },
      {
        text: "",
        sortable: false,
        value: "",
        fixed: true,
        width: "4%",
      },
    ],
    crn_tags: [],
    delivery_contacts: [],
    places: [],
    places_pickup: [],
    error: [],
    filelist: [],
    containers: {
      container_number: "",
      container_size: "",
      container_weight: "",
      container_volume: "",
      container_qty: "",
      lfd: "",
      // delivery_to: "",
      // deliveryLongitude: "",
      // deliveryLatitude: "",
    },

    rules: [(v) => !!v || "Input is required."],
    fieldrequired: [(v) => !!v || "This field is required"],
    container_number_rules: [
      (text) => {
        if (text == "") "Container # is required.";

        let msg = "The first 4 characters are alpha and followed by 7 digits.";
        if (text.length == 11) {
          if (
            /^[a-zA-Z]*$/.test(text.substring(0, 4)) &&
            /^[0-9]*$/.test(text.substring(4, text.length))
          ) {
            return true;
          }
          return msg;
        }
        return "First 4 characters are alpha and followed by 7 digits.";
      },
    ],
    tagsValidation: [
      {
        classes: "t-new-tag-input-text-error",
        rule: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
        disableAdd: true,
      },
    ],
    dateValidation: [
      (date) => {
        if (date === "") {
          return "This field is required";
        } else {
          if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
            return true;
          }

          return "The date should be in YYYY-MM-DD format";
        }
      },
    ],
    timeValidation: [
      (time) => {
        if (/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time)) {
          return true;
        }

        return "The time should be in HH:mm format";
      },
    ],
    phoneNumberValidation: [
      (number) => {
        if (number !== "") {
          if (
            /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(number)
          ) {
            return true;
          }

          return "The phone number should be valid (xxx xxx xxxxx)";
        } else {
          return true;
        }
      },
    ],

    receivingTimeFromText: null,
    receivingTimeToText: null,

    fields: {
      documents: [],
      company_name: "",
      delivery_contacts: [],
      customer_ref_number: [],
      mode: "",
      carrier: "",
      carrier_name: "",
      vessel: "",
      mbl_number: "",
      eta: "",
      pickup_location: "",
      delivery_location: "",
      receiving_location_days: [],
      receiving_time: { from: "", to: "" },
      receiving_location_contact: "",
      description: "",
      containers: [
        // {
        //     container_number: "",
        //     container_size: "",
        //     container_weight: "",
        //     container_volume: "",
        //     container_qty: "",
        //     lfd: ""
        // delivery_to: "",
        // deliveryLongitude: "",
        // deliveryLatitude: "",
        // }
      ],
      trucker: "",
    },
    documentErrorMsg: "",
    errorMsgForContainers: false,
    checked: true,
    dialogrequired: false,
    masks: {
      weekdays: "WWW",
      navMonths: "MMMM",
    },
    isShowErrorLFD: false,
    }
  }
  ,
  async mounted() {
    
    //this.consigneeListobject  = this.consignee;
    // theterminalitems
    let that = this;
    await axios
      .get(`${process.env.VUE_APP_PO_URL}/getterminals`)
      .then(function (response) {
        if (response.data) {
          console.log(response.data);
          that.theterminalitems = response.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  updated() {
    if (this.selectedCustomer) {
      var takeCustomername = this.customer.find(
        (e) => e.id == this.selectedCustomer
      );
      this.fields.company_name = takeCustomername.company_name;
    }
  },
  watch: {
    consignee(c){
       this.consigneeListobject = c
    },
    crn_tags(nv) {
      if (nv.length > 0) {
        let temp = [];
        nv.map((obj) => {
          temp.push({ customer_ref_number: obj.text });
        });

        this.fields.customer_ref_number = temp;
      } else this.fields.customer_ref_number = [];
    },
    delivery_contacts(nv) {
      if (nv.length > 0) {
        let temp = [];
        nv.map((obj) => {
          temp.push(obj.text);

          let uniq = [...new Set(temp)];
          this.fields.delivery_contacts = uniq;
        });
      } else this.fields.delivery_contacts = [];
    },
    searchPlacePickup(val) {
      if (val) {
        this.getPickupLocation(val);
      }
    },
    searchPlace(val) {
      if (val) {
        this.getDeliveryLocation(val);
      }
    },
    // "containers.container_number"() {
    //     let text = jQuery(".v-messages__message").html()
    //     this.notificationError(text)
    // },
    // "fields.carrier"(nv) {
    //     let carrier = this.carriers.find((carrier) => carrier.id === nv)
    //     this.fields.carrier_name = carrier.name
    // },
    hasSubmitted(nv) {
      if (!nv) {
        this.crn_tags = [];
        this.delivery_contacts = [];
        this.places = [];
        (this.places_pickup = []), (this.error = []);
        this.filelist = [];
        this.containers = {
          container_number: "",
          container_size: "",
          container_weight: "",
          container_volume: "",
          container_qty: "",
          delivery_to: "",
          deliveryLongitude: "",
          deliveryLatitude: "",
        };

        this.fields = {
          documents: [],
          company_name: "",
          consignee: "",
          delivery_contacts: [],
          customer_ref_number: [],
          mode: "",
          carrier: "",
          carrier_name: "",
          vessel: "",
          mbl_number: "",
          eta: "",
          pickup_location: "",
          delivery_location: "",
          receiving_location_days: [],
          receiving_time: { from: "", to: "" },
          receiving_location_contact: "",
          description: "",
          containers: [],
          trucker: "",
        };
      }
    },
  },
  computed: {
      consigneeList: {
        get() {        
          let s =  this.consigneeListobject;
          return s;
        }
      },
      getSearchedConsignee: {
      get() {
        return this.newConsignee !== null
          ? this.newConsignee.toString()
          : this.newConsignee;
      },
      set(input) {
        let temp = [];
        this.consignee.map((list) => {
          temp.push(list.name);
        });
        if (temp.includes(input)) {
          this.hasNewChassis = 0;
        } else if (this.newConsignee === "" || this.newConsignee === null) {
          this.hasNewChassis = 0;
        } else {
          this.hasNewChassis = 1;
        }
        this.newConsignee = input;
      },
    },
    isCustomer: {
      get() {
        if (typeof this.customer != "undefined") {
          if (this.customer.length) {
            return 1;
          }
        }
        return 0;
      },
    },
    customerdropdown: {
      get() {
        return this.customer;
      },
    },
    theterminals: {
      get() {
        return this.theterminalitems;
      },
    },
    getEndDate() {
      var endDate = new Date(
        this.dateNow.getFullYear(),
        this.dateNow.getMonth() + 1,
        10
      );
      return endDate.toISOString().slice(0, 10);
    },
  },
  methods: {
   async getConsigneeList(){
       await axios
      .get(`${process.env.VUE_APP_PO_URL}/getAllConsigneeByCustomer/`+this.selectedCustomer)
      .then(function (response) {
        if (response.data) {
         this.consigneeListobject = response.data.allConsignee
        }
      })
      .catch((error) => {
        console.log(error);
      });
    },
    handleNewConsignee() {      
      let data = {
        customerID:  this.selectedCustomer,
        consigneeName: this.newConsignee,
        companyKey: this.customerKey
      };

      if (this.newConsignee === null || this.newConsignee === "") {
        this.dispatchNotificationError("New Consignee should not be blank/null");
      } else {         
        axios
          .post(`${process.env.VUE_APP_PO_URL}/consignee-save`, data)
          .then((response) => {
            this.dispatchNotifcation(
              '<i class="ic-check-mark"></i>&nbsp;<span class="text-message">' +
               'Consignee has been saved.' +
                "</span>"
            );
            this.fields.consignee = response.data.consigneeID;
            this.hasNewChassis = 0;
                console.log( response.data);    
            this.consigneeListobject = response.data.allConsignee
            
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    ...globalMethods,
    getMinDate() {
      if (this.fields.eta !== null && this.fields.eta !== "") {
        let dateTime = new Date(this.fields.eta);
        return new Date(new Date(dateTime).setDate(dateTime.getDate() + 1));
      }
    },
    handleReceivingTimeChanged(e, field) {
      if (e.value !== null) {
        let momentOb = moment(e.value, "hh:mm A");

        if (field === "from") {
          this.fields.receiving_time.from = momentOb.format("HH:mm");
        } else if (field === "to") {
          this.fields.receiving_time.to = momentOb.format("HH:mm");
        }
      }
    },
    clearDate() {
      if (this.fields.eta == null) {
        this.fields.eta = "";
      } else {
        this.fields.eta = "";
      }
      this.isDateRequired = false;
      console.log(this.fields);
    },
    clearDateContainer(date, index) {
      if (this.fields.containers.length > 0) {
        this.fields.containers[index].lfd = "";

        if (this.$refs.lfd_ref_do.value_ !== null) {
          this.$refs.lfd_ref_do.value_ = null;
        }
      }
    },
    checkKeyUpVal(e) {
      if (e.key === "Enter" || e.keyCode === 13) {
        this.$refs.etaCf_ref_do.hidePopover(); // close popover on click enter
      }
    },
    customDateValidation(date) {
      if (date == "" || date == null) {
        return "This field is required";
      } else {
        return "";
      }
    },
    changeDateFormat(val) {
      console.log(val);
    },
    checkErrorShowing(e, field) {
      if (e !== "") {
        if (field === "eta") {
          this.isDateRequired = true;
          if (
            typeof this.$refs.etaCf_ref_do !== "undefined" &&
            this.$refs.etaCf_ref_do.value_ !== null
          ) {
            if (this.$refs.etaCf_ref_do.value_.getFullYear() === 2001) {
              this.$refs.etaCf_ref_do.value_.setFullYear(moment().year());
            }
          }

          if (this.fields.eta !== null && this.fields.eta !== "") {
            this.fields.eta = moment(e).format("YYYY-MM-DD");
          } else {
            this.fields.eta = "";
          }
        }
      }
    },
    checkErrorShowingLFD(e, field, index) {
      if (e !== "") {
        if (field === "lfd") {
          if (
            typeof this.$refs.lfd_ref_do !== "undefined" &&
            this.$refs.lfd_ref_do.value_ !== null
          ) {
            if (this.$refs.lfd_ref_do.value_.getFullYear() === 2001) {
              this.$refs.lfd_ref_do.value_.setFullYear(moment().year());
            }
          }

          if (
            this.fields.containers[index].lfd !== null &&
            this.fields.containers[index].lfd !== ""
          ) {
            this.fields.containers[index].lfd = moment(e).format("YYYY-MM-DD");
          } else {
            this.fields.containers[index].lfd = "";
          }
        }
      }
    },
    addContainer() {
      this.isAdding = true;
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    dropFile(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();
    },
    closenow() {
      window.location.href = document.location.origin;
    },
    onChange() {
      this.fields.documents = [];
      this.filelist = [...this.$refs.file.files];
      let table = this.filelist;
      let errorCount = 0;
      let keys = [];

      Object.keys(table).forEach(function eachKey(key) {
        if (table[key].type != "application/pdf") {
          keys.push(key);
          errorCount++;
        }
      });

      if (errorCount > 0) {
        this.errorFile = 1;
        this.allFieldsValid = false;
      } else {
        this.errorFile = 0;
        this.allFieldsValid = true;
      }

      keys.forEach((e) => {
        this.filelist.splice(e, keys.length + 1);
      });

      if (this.filelist.length > 1) {
        this.filelist.splice(1, this.filelist.length);
        this.fields.documents.push(this.filelist[0]);
        this.moreFilesError = true;
      } else {
        this.fields.documents.push(this.filelist[0]);
        this.moreFilesError = false;
      }
    },
    removeFile(i) {
      this.fields.documents.splice(i, 1);
    },
    timetopass() {
      let finalEmailAddress =
        this.fields.delivery_contacts.length > 0
          ? this.options.map((o) => {
              return o.text;
            })
          : [];
      return finalEmailAddress;
    },
    timetopassreference() {
      let finalrefrenceNumber =
        this.optionsReferrence.length > 0
          ? this.optionsReferrence.map(({ text }) => ({
              customer_ref_number: text,
            }))
          : [];
      return JSON.stringify(finalrefrenceNumber);
    },
    handleReplace() {
      jQuery(document).find(".triggerelement").trigger("click");
    },
    getFileURL(file) {
      return URL.createObjectURL(file);
    },
    confirmDatePicker(date) {
      if (this.pickerLabel === "Pick ETA Date") {
        this.fields.eta = date;
        //} else if(this.pickerEdit){
        //this.fields.containers[this.currentindex].lfd = date
        //this.pickerEdit = 0;
      } else {
        this.fields.containers[this.currentIndexOfLfd].lfd = date;
      }
      this.isDatePickerOpen = false;
    },
    showcustom(state, m) {
      this.pickerDefaultdata = state;
      this.currentstatepicker = m;
      this.dialogDatepicker = true;
    },
    async getDeliveryLocation(val) {
      if (val.length > 4) {
        this.loadingLocationPlaces = true;

        await fetch(
          `${process.env.VUE_APP_MAPBOX_PLACE_API_URL}/` +
            val +
            `.json?access_token=${process.env.VUE_APP_MAPBOX_PUBLIC_KEY}&country=US`
        )
          .then((response) => response.json())
          .then((json) => {
            this.loadingLocationPlaces = false;
            this.places = [...json.features];
          })
          .catch((e) => {
            this.loadingLocationPlaces = false;
            console.log(e);
          });
      }
    },
    async getPickupLocation(val) {
      if (val.length > 4) {
        this.loadingLocationPlaces = true;

        await fetch(
          `${process.env.VUE_APP_MAPBOX_PLACE_API_URL}/` +
            val +
            `.json?access_token=${process.env.VUE_APP_MAPBOX_PUBLIC_KEY}`
        )
          .then((response) => response.json())
          .then((json) => {
            this.loadingLocationPlaces = false;
            this.places_pickup = [...json.features];
          })
          .catch((e) => {
            this.loadingLocationPlaces = false;
            console.log(e);
          });
      }
    },
    changeDeliveryLocationState() {
      this.callApiForDeliveryLoc = !this.callApiForDeliveryLoc;
    },
    clearSearchPlaces() {
      this.places = [];
    },
    clearSearchPlacesPickup() {
      this.places_pickup = [];
    },
    handleAddContainer() {
      this.error = [];
      let hasfoundlfderror = 0;

      Object.entries(this.fields.containers).forEach(([key, value]) => {
        if (value.container_number === "") {
          this.error.push(key);
        } else if (key == "lfd") {
          let eta = new Date(this.fields.eta).getTime();
          let lfd = new Date(value).getTime();
          if (eta > lfd) {
            this.errorlfd = 1;
            this.error.push(key);
            hasfoundlfderror = 1;
          }
        }
      });

      if (!hasfoundlfderror) {
        this.errorlfd = 0;
      }

      if (!this.error.length > 0) {
        /**
         * The Delivery to fields is changed into LFD field
         */

        // const selectedPlace = this.places.find(
        //     (element) => element.place_name === this.containers.delivery_to
        // );

        // this.containers.deliveryLongitude = selectedPlace.geometry
        //     ? selectedPlace.geometry.coordinates[0]
        //     : null,
        // this.containers.deliveryLatitude = selectedPlace.geometry
        //     ? selectedPlace.geometry.coordinates[1]
        //     : null

        /**
         * END
         */

        if (this.$refs.containerForm.validate()) {
          this.fields.containers.push(this.containers);
          this.$nextTick(() => {
            this.$refs.containerForm.resetValidation();
          });
          this.isAdding = false;
          this.containers = {
            container_number: "",
            container_size: "",
            container_weight: "",
            container_volume: "",
            container_qty: "",
            lfd: "",
            // delivery_to: "",
            // deliveryLongitude: "",
            // deliveryLatitude: "",
          };
        }
      }
    },
    removeContainer(item, i) {
      if (item.container_number !== "" && this.fields.containers.length == 1) {
        if (
          typeof this.$refs.lfd_ref_do !== "undefined" &&
          this.$refs.lfd_ref_do.value_ !== null
        ) {
          this.$refs.lfd_ref_do.value_ = null;
        }

        return (
          (item.container_number = ""),
          (item.container_size = ""),
          (item.container_weight = ""),
          (item.container_volume = ""),
          (item.container_qty = ""),
          (item.lfd = "")
        );
      } else if (
        item.container_number == "" &&
        this.fields.containers.length == 1
      ) {
        if (
          typeof this.$refs.lfd_ref_do !== "undefined" &&
          this.$refs.lfd_ref_do.value_ !== null
        ) {
          this.$refs.lfd_ref_do.value_ = null;
        }

        return (
          (item.container_number = ""),
          (item.container_size = ""),
          (item.container_weight = ""),
          (item.container_volume = ""),
          (item.container_qty = ""),
          (item.lfd = "")
        );
      } else {
        this.fields.containers.splice(i, 1);
        this.checked = true;
      }
    },
    async handleSubmit() {
      if (this.fields.containers.length > 0) {
        let result = this.fields.containers.every((val) => {
          if (val.container_number == "") {
            return false;
          }
          return true;
        });
        if (!result) {
          this.checked = false;
          return;
        } else {
          this.checked = true;
        }
      } else {
        this.dialogrequired = true;
      }

      if (this.fields.eta == null || this.fields.eta == "") {
        return (this.dateReq = true);
      } else {
        this.dateReq = false;
      }

      if (!this.fields.delivery_contacts.length > 0) {
        this.errorMsg = "Please input atleast 1 delivery contact";
      }
      if (this.fields.documents.length == 0) {
        this.documentErrorMsg = "Please add document";
      } else {
        this.documentErrorMsg = "";
      }

      if (
        this.$refs.form.validate() &&
        this.fields.delivery_contacts.length > 0 &&
        this.fields.documents.length > 0 &&
        this.$refs.containerForm.validate()
      ) {
        let formData = new FormData();
        this.fields.pickup_location =
          this.terminal !== ""
            ? this.theterminals.find((e) => e.id == this.terminal).name
            : "";
        formData.append("flagCreatedFE", 1);
        formData.append("xmbl", this.fields.mbl_number);
        //formData.append('xnote_mbl', this.fields.description)
        formData.append("container_commodity", this.fields.description);
        formData.append("xcontacts[]", this.fields.delivery_contacts);
        formData.append("xconsignee", this.fields.consignee);
        formData.append("xcarrier", this.fields.carrier);
        formData.append("xcarrier_name", this.fields.carrier_name);
        formData.append("xvessel", this.fields.vessel);
        formData.append('consignee_id',this.fields.consignee);
        formData.append('customer_id',this.selectedCustomer);
        formData.append('cf_form_type','consigneeFormLink');
        formData.append(
          "xreferrence[]",
          JSON.stringify(this.fields.customer_ref_number)
        );
        formData.append(
          "xcontainerList",
          JSON.stringify(this.fields.containers)
        );
        formData.append("ETA", this.fields.eta);

        //formData.append('xlocation_at_name', this.fields.pickup_location)
        formData.append("xlocation_at", this.terminal);
        formData.append("location", "CF");
        formData.append("xcfcode", this.$route.params.id);

        formData.append("trucker", this.trucker.trucker_id);
        formData.append("xcompany_name", this.fields.company_name);
        formData.append("xmode", this.fields.mode);
        formData.append("xdelivery_location", this.fields.delivery_location);
        formData.append(
          "xreceiving_location_days",
          JSON.stringify(this.fields.receiving_location_days)
        );
        formData.append(
          "xreceiving_time",
          JSON.stringify(this.fields.receiving_time)
        );
        formData.append(
          "xreceiving_location_contact",
          this.fields.receiving_location_contact
        );

        this.fields.documents.map((file, key) => {
          formData.append(`files[${key}]`, file);
        });

        await axios
          .post(`${process.env.VUE_APP_PO_URL}/save-do-form`, formData)
          .then((response) => {
            this.$emit("submitted", this.fields);
            return response.data;
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          });
      }
    },

    handleTimeChange(value, field) {
      if (value) {
        let momentOb = moment(value, "hh:mm A");
        if (momentOb.isValid()) {
          if (field == "from") {
            this.receivingTimeFromText = momentOb.format("hh:mm A");
            this.fields.receiving_time.from = momentOb.format("HH:mm");
          } else if (field == "to") {
            this.receivingTimeToText = momentOb.format("hh:mm A");
            this.fields.receiving_time.to = momentOb.format("HH:mm");
          }
        } else if (value[0] && value[0] > 2) {
          value = "0" + value;
          momentOb = moment(value, "hh:mm A");
          if (momentOb.isValid()) {
            if (field == "from") {
              this.receivingTimeFromText = momentOb.format("hh:mm A");
              this.fields.receiving_time.from = momentOb.format("HH:mm");
            } else if (field == "to") {
              this.receivingTimeToText = momentOb.format("hh:mm A");
              this.fields.receiving_time.to = momentOb.format("HH:mm");
            }
          }
        }
      }
    },

    timeRules(time = "") {
      time = time !== "" ? moment(time, "hh:mm A").format("HH:mm") : ""; // format to 24 hr
      return {
        receivingTimeFrom: () => {
          let msg = "";
          if (_.isEmpty(time)) {
            msg = "This field is required";
          } else {
            time = moment(time, "HH:mm", true);
            if (time.isValid()) {
              let to = moment(this.fields.receiving_time.to, "HH:mm", true);
              if (moment(to).isBefore(time)) {
                msg =
                  "Receiving Opens should not be later than Receiving Closes";
              } else {
                return true;
              }
            } else {
              msg = "Invalid Time ex. HH:mm";
            }
          }
          return msg;
        },
        receivingTimeTo: () => {
          let msg = "";
          if (_.isEmpty(time)) {
            msg = "This field is required";
          } else {
            time = moment(time, "HH:mm", true);
            if (time.isValid()) {
              let from = moment(this.fields.receiving_time.from, "HH:mm", true);
              if (moment(time).isBefore(from)) {
                msg =
                  "Receiving Closes should not be earlier than Receiving Opens";
              } else {
                return true;
              }
            } else {
              msg = "Invalid Time ex. HH:mm";
            }
          }
          return msg;
        },
      };
    },
    checkforFirstRowDataFunction(item) {
      if (
        item.container_number == "" &&
        item.container_size == "" &&
        item.container_weight == "" &&
        item.container_volume == "" &&
        item.container_qty == "" &&
        item.lfd == "" &&
        this.fields.containers.length == 1
      ) {
        return true;
      } else {
        return false;
      }
    },
    inputText() {
      this.checked = true;
    },
    checkingg(item) {
      if (this.checked) return;
      if (item.container_number == "" && this.fields.containers.length > 0) {
        return "Container # is required.";
      } else {
        return "";
      }
    },
    checkforErrors(item) {
      let error = false;
      if (this.fields.containers.length > 1) {
        // let error1 = this.fields.containers.filter(val =>{
        //         if(val.container_number == ''){
        //             return val
        //         }
        //     })
        //     if(error1.length > 0){
        //         return false
        //     }
        if (item.container_number == "") {
          if (
            item.container_size == "" &&
            item.container_weight == "" &&
            item.container_volume == "" &&
            item.container_qty == "" &&
            item.lfd == ""
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          // if(val.container_number !== ''){
          if (item.container_number.length == 11) {
            if (
              /^[a-zA-Z]*$/.test(item.container_number.substring(0, 4)) &&
              /^[0-9]*$/.test(
                item.container_number.substring(4, item.container_number.length)
              )
            ) {
              return true;
            }
          } else {
            return false;
          }
          // }
        }
        //    error =  this.fields.containers.every(val => {
        //     if(val.container_number !== ''){
        //         if(val.container_number.length == 11){
        //             if (
        //                 /^[a-zA-Z]*$/.test(item.container_number.substring(0, 4)) &&
        //             /^[0-9]*$/.test(item.container_number.substring(4, item.container_number.length))
        //             ) {
        //                 return true;
        //             }
        //         }else{
        //             return false
        //         }
        //     }
        //     return false
        //    })
      } else {
        if (item.container_number !== "") {
          if (item.container_number.length == 11) {
            if (
              /^[a-zA-Z]*$/.test(item.container_number.substring(0, 4)) &&
              /^[0-9]*$/.test(
                item.container_number.substring(4, item.container_number.length)
              )
            ) {
              return true;
            }
            return false;
          }
        } else if (
          item.container_number == "" &&
          item.container_size == "" &&
          item.container_weight == "" &&
          item.container_volume == "" &&
          item.container_qty == "" &&
          item.lfd == ""
        ) {
          return true;
        } else {
          error = false;
        }
      }
      if (error) {
        this.errorMsgForContainers = true;
        return true;
      } else {
        this.errorMsgForContainers = false;
        return false;
      }
    },
    checkforErrorsMsg(text, index) {
      if (this.fields.containers.length == 1 && text == "") {
        if (
          this.fields.containers[index].container_size !== "" ||
          this.fields.containers[index].container_weight !== "" ||
          this.fields.containers[index].container_volume !== "" ||
          this.fields.containers[index].container_qty !== "" ||
          this.fields.containers[index].lfd !== ""
        ) {
          return "Container # is required.";
        } else {
          return "";
        }
      } else {
        if (text == "") {
          return "Container # is required.";
        } else {
          let msg =
            "The first 4 characters are alpha and followed by 7 digits.";
          if (text.length == 11) {
            if (
              /^[a-zA-Z]*$/.test(text.substring(0, 4)) &&
              /^[0-9]*$/.test(text.substring(4, text.length))
            ) {
              return "";
            }
            return msg;
          }
          return "First 4 characters are alpha and followed by 7 digits.";
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/vc_calender_delivery_order_form.scss";
.close-button-check-lfd .mdi-close::before {
  content: "\F0156";
  display: none;
}
.expectedTimeArrival .v-input__append-inner {
  position: relative;
  top: 7px;
}
.receivingOpens .v-input__append-inner {
  position: relative;
  top: 7px;
}

.customerform_addContainers .v-text-field {
  height: 37px !important;
}

.customerform_addContainers .inlinefield .v-input__append-inner {
  position: relative;
  top: 7px;
}

.customerform_public .text-fields {
  padding-top: 0px;
  margin-top: 4px;

  &.text-end {
    input {
      text-align: end !important;
    }
  }

  input {
    font-family: "Inter-Regular", sans-serif !important;
    font-size: 14px;
  }

  .ti-input {
    height: 40px;

    input {
      padding: 0;
    }
  }
}
.customerform_public .customerForm.inner {
  .text-fields {
    padding-top: 0px;
    margin-top: 0 !important;
  }

  .customerform_addContainers {
    .text-fields {
      .v-input__control {
        height: 100%;

        .v-input__slot {
          min-height: 100% !important;
          max-height: 100% !important;
          padding: 0 6px;

          input {
            font-family: "Inter-Regular", sans-serif !important;
            padding: 2px 0;
          }
        }
      }
    }

    &.v-data-table {
      .v-data-table__wrapper {
        table {
          thead {
            tr {
              th {
                padding: 10px !important;
              }
            }
          }
        }
      }
    }
  }

  .vc-date-picker__wrapper {
    .create-do-eta-dxdate-vc {
      height: 40px;
      margin-top: 0;
      font-size: 14px !important;
      padding: 4px 8px !important;
    }

    .vc-date-clear-button {
      position: absolute;
      right: 12px;
      top: 7px;
    }
  }

  .dxdatebox-time-clear {
    height: 40px;

    .date-field-dxtime {
      height: 40px;
      border: 1px solid #b4cfe0;

      .dx-dropdowneditor-input-wrapper {
        .dx-placeholder {
          color: #b4cfe0 !important;
        }

        input {
          font-family: "Inter-Regular", sans-serif !important;
          color: #4a4a4a;
          caret-color: #4a4a4a;
        }

        .dx-texteditor-buttons-container {
          .dx-clear-button-area {
            .dx-icon.dx-icon-clear {
              font-size: 16px;

              &::before {
                content: "\e932";
                background-image: url("/icons/close/close-red.svg") !important;
                background-position: center !important;
                background-repeat: no-repeat !important;
                background-size: cover !important;
                width: 14px !important;
                height: 14px !important;
                color: #4a4a4a !important;
                padding: 0px;
              }
            }
          }
        }
      }
    }

    .dx-dropdowneditor-icon {
      color: #4a4a4a !important;
    }
  }
}
.container-nbr-ph.v-text-field.v-text-field--solo .v-input__control input {
  font-size: 14px;
}
.container-size.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: inherit;
  border-style: none !important;
  border-width: 0 0 0 0;
}
.container-size.v-text-field > .v-input__control > .v-input__slot:after {
  background-color: currentColor;
  border-color: currentColor;
  border-style: none !important;
  border-width: 0 0 0 0;
  transform: scaleX(0);
}
.container-size.v-input.text-fields.v-text-field--full-width
  > .v-input__control
  > .v-input__slot {
  margin-top: 0 !important;
  width: 150px;
}
.customerform_addContainers.v-data-table .v-data-table__empty-wrapper {
  display: none;
}
.create-do-eta-dxdate {
  height: 40px;
  border: 1px solid #b4cfe0;
  border-radius: 4px;

  input {
    font-family: "Inter-Regular", sans-serif;
    caret-color: #4a4a4a;
    color: #4a4a4a;
    padding-left: 12px;
    font-size: 14px;
  }

  .dx-placeholder {
    color: #b4cfe0 !important;

    &::before {
      padding-left: 12px;
    }
  }

  .dx-texteditor-buttons-container {
    .dx-dropdowneditor-button {
      width: 45px;

      .dx-dropdowneditor-icon {
        width: 45px;
        color: #4a4a4a;

        &::before {
          content: "";
          background-image: url("/icons/calendar-black.svg") !important;
          background-position: center !important;
          background-repeat: no-repeat !important;
          background-size: cover !important;
          width: 18px !important;
          height: 18px !important;
        }
      }
    }
  }
}
</style>
