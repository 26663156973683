<template>
  <div>
    <div class="customerForm">
      <div class="mt-5">
        <div class="form-header-wrap">
          <div class="flex flex-column justify-center">
            <svg
              width="74"
              height="74"
              viewBox="0 0 74 74"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M37 0.333008C57.2505 0.333008 73.6667 16.7492 73.6667 36.9997C73.6667 57.2501 57.2505 73.6663 37 73.6663C16.7496 73.6663 0.333374 57.2501 0.333374 36.9997C0.333374 16.7492 16.7496 0.333008 37 0.333008ZM48.8055 25.8885L32.4167 42.2773L25.1946 35.0551C24.1206 33.9812 22.3794 33.9812 21.3055 35.0551C20.2316 36.1291 20.2316 37.8703 21.3055 38.9442L30.4722 48.1109C31.5461 49.1848 33.2873 49.1848 34.3612 48.1109L52.6946 29.7775C53.7685 28.7036 53.7685 26.9624 52.6946 25.8885C51.6206 24.8145 49.8794 24.8145 48.8055 25.8885Z"
                fill="#16B442"
              />
            </svg>
            <span class="doTitle">Delivery order has been submitted!</span>
            <div class="mt-2 text-center" style="padding-inline: 189px">
              <span>
                You have successfully submitted the delivery order request. You
                will receive an email confirmation for your submitted request.
                Look out for final the acceptance of your request.
              </span>
            </div>
          </div>
        </div>

        <div class="px-16">
          <div class="mt-5 mb-5">
            <span class="detail-header"
              >Check a preview of the submitted DO below:</span
            >
          </div>

          <div class="detail-wrapper">
            <div class="row">
              <div class="col-md-4 pt-0">
                <span class="detail-label">Company Name</span>
              </div>
              <div class="col-md-8 pt-0">
                <span class="detail-data"> {{ formdata.company_name }} </span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 pt-0">
                <span class="detail-label">Delivery Contacts</span>
              </div>
              <div class="col-md-8 pt-0">
                <span
                  v-for="(contact, i) in formdata.delivery_contacts"
                  :key="i"
                  class="detail-data"
                >
                  {{
                    contact +
                    `${formdata.delivery_contacts.length - 1 === i ? "" : ","}`
                  }}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 pt-0">
                <span class="detail-label">My Reference No.</span>
              </div>
              <div class="col-md-8 pt-0">
                <span
                  v-for="(ref, i) in formdata.customer_ref_number"
                  :key="i"
                  class="detail-data"
                >
                  {{
                    ref.customer_ref_number +
                    `${
                      formdata.customer_ref_number.length - 1 === i ? "" : ","
                    }`
                  }}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 pt-0">
                <span class="detail-label">Consignee</span>
              </div>
              <div class="col-md-8 pt-0">
                <span class="detail-data">
                  {{ consgineeName }}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 pt-0">
                <span class="detail-label">Carrier</span>
              </div>
              <div class="col-md-8 pt-0">
                <span class="detail-data">{{
                  getcarrier(formdata.carrier)
                }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 pt-0">
                <span class="detail-label">Vessel</span>
              </div>
              <div class="col-md-8 pt-0">
                <span class="detail-data">{{ formdata.vessel }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 pt-0">
                <span class="detail-label">MBL Number</span>
              </div>
              <div class="col-md-8 pt-0">
                <span class="detail-data">{{ formdata.mbl_number }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 pt-0">
                <span class="detail-label">ETA</span>
              </div>
              <div class="col-md-8 pt-0">
                <span class="detail-data">{{ formdata.eta }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 pt-0">
                <span class="detail-label">Pickup Location</span>
              </div>
              <div class="col-md-8 pt-0">
                <span class="detail-data">{{ formdata.pickup_location }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 pt-0">
                <span class="detail-label">Delivery Location</span>
              </div>
              <div class="col-md-8 pt-0">
                <span class="detail-data">{{
                  formdata.delivery_location
                }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 pt-0">
                <span class="detail-label">Receiving Location Days</span>
              </div>
              <div class="col-md-8 pt-0">
                <span
                  v-for="(day, i) in formdata.receiving_location_days"
                  :key="i"
                  class="detail-data"
                >
                  {{
                    day +
                    `${
                      formdata.receiving_location_days.length - 1 === i
                        ? ""
                        : ","
                    }`
                  }}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 pt-0">
                <span class="detail-label">Receiving Location Hours</span>
              </div>
              <div class="col-md-8 pt-0">
                <span class="detail-data">{{
                  formdata.receiving_time.from +
                  " - " +
                  formdata.receiving_time.to
                }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 pt-0">
                <span class="detail-label">Receiving Location Contact</span>
              </div>
              <div class="col-md-8 pt-0">
                <span class="detail-data">{{
                  formdata.receiving_location_contact
                }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 pt-0">
                <span class="detail-label">Description</span>
              </div>
              <div class="col-md-8 pt-0">
                <span class="detail-data">{{ formdata.description }}</span>
              </div>
            </div>

            <div class="row pb-6">
              <div class="col-md-12">
                <label class="detail-table-header">Containers</label>
                <v-data-table
                  class="containers-table mt-2"
                  :headers="headers"
                  :items="formdata.containers"
                  :items-per-page="5"
                  hide-default-footer
                  dense
                >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="(item, index) in items" :key="index">
                        <td class="text-start">
                          {{ item.container_number }}
                        </td>
                        <td class="text-end">
                          {{ item.container_size }}
                        </td>
                        <td class="text-end">
                          {{ item.container_weight }}
                        </td>
                        <td class="text-end">
                          {{ item.container_volume }}
                        </td>
                        <td class="text-end">
                          {{ item.container_qty }}
                        </td>
                        <td class="text-start">
                          {{ item.lfd }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                  <template v-slot:[`no-data`]>
                    <tbody>
                      <tr>
                        <td colspan="6" class="text-center">
                          No Containers added
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </div>
            </div>
          </div>
          <span class="detail-help"
            >If there were any errors in your submission,, reach out to us at
            <a href="mailto:help@dispatch.com">help@dispatch.com</a></span
          >
        </div>

        <div class="form-footer-wrap mt-6">
          <div class="flex flex-row">
            <button @click="handleClose" type="button" class="cancel-btn">
              Close
            </button>
            <button
              @click="handleSaveAnother"
              type="button"
              class="submit--detail ml-2"
            >
              Submit Another DO
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import globalMethods from "../../utils/globalMethods";
export default {
  name: "CustomerDeliveryOrderFormSubmitted",
  props: ["formdata", "carriers"],
  components: {},
  data: () => ({
    headers: [
      {
        text: "Container #",
        align: "center",
        sortable: false,
        value: "container_number",
      },
      {
        text: "Size",
        align: "end",
        sortable: false,
        value: "size",
      },
      {
        text: "Weight",
        align: "end",
        sortable: false,
        value: "weight",
      },
      {
        text: "Volume",
        align: "end",
        sortable: false,
        value: "volume",
      },
      {
        text: "Quantity",
        align: "end",
        sortable: false,
        value: "quantity",
      },
      {
        text: "LFD",
        align: "center",
        sortable: false,
        value: "lfd",
      },
    ],
    consgineeName: "",
    containers: [
      {
        container_number: "",
        container_size: "",
        container_weight: "",
        container_volume: "",
        container_qty: "",
        lfd: "",
        // delivery_to: "",
        // deliveryLongitude: "",
        // deliveryLatitude: ""
      },
    ],
  }),
  methods: {
    ...globalMethods,
    handleClose() {
      window.localStorage.removeItem("formdata");
      window.localStorage.removeItem("hasSubmitted");
      window.location.replace("/login");
    },
    handleSaveAnother() {
      window.localStorage.removeItem("formdata");
      window.localStorage.removeItem("hasSubmitted");
      this.$emit("saveAnother");
    },
    getcarrier(v) {
      if (v) {
        let f = this.carriers.find((e) => {
          return e.id == v;
        });
        return f.name;
      }
    },
    async getconsignee() {
      try {
        const id = this.formdata.consignee;
        if (!id) return;
        const res = await axios.get(
          `${process.env.VUE_APP_PO_URL}/get-do-consignee/${id}`
        );
        if (res.status == 200) {
          this.consgineeName = res.data.data.name;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getconsignee();
  },
};
</script>
<style>
.doTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  color: #4a4a4a;
}
.detail-header {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4a4a4a;
}
.detail-table-header {
  font-weight: 600;
  font-size: 16px;
  color: #4a4a4a;
}
.detail-wrapper {
  padding: 16px;
  gap: 24px;
  width: 800px;
  height: auto;
  background: #f7f7f7;
  border-radius: 4px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.detail-label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.detail-data {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
  flex: none;
  order: 1;
  flex-grow: 1;
}
.detail-help {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4a4a4a;
}
</style>
